<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-8 col-12">Open SMS</div>
        <div class="header-label col-lg-4 col-12">
            <div class="intelligent-campagin-modal-header text-center" v-if="this.smsmessage != ''">Message Preview
            </div>
        </div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="row">
            <div class="col-lg-8 col-md-8 col-12">
                <div class="layout-content-section">
                    <div class="form-box-outer">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="custom-form-group">
                                    <label for="formmessageInput" class="form-label">SMS Content<span
                                            class="text-danger">*</span></label>
                                    <textarea type="text" id="formmessageInput" v-model="smsmessage"
                                        class="form-control custom-text-area-scroll" placeholder="Enter SMS Content"
                                        v-on:keyup="htmlconvertmsg()" autocomplete="off" rows="5" maxlength="1024"
                                        :style="{ resize: 'vertical' }" :disabled="btnloader"></textarea>
                                    <div class="custom-error" v-if="v$.smsmessage.$error">
                                        {{ v$.smsmessage.$errors[0].$message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-12" v-for="(numbersettingset, index) in mobilevalueset"
                                v-bind:key="index">
                                <div class="row">
                                    <div class="col-lg-10 col-md-10 col-12">
                                        <div class="custom-form-group">
                                            <label for="formmessageInput" class="form-label">Mobile No.<span
                                                    class="text-danger">*</span></label>
                                            <div class="input-group custom-input-group">
                                                <div class="col-lg-4 col-md-4 col-12">
                                                    <Multiselect v-model="countrycode" :options="countryCodeList"
                                                        :searchable="true" label="label" placeholder="Select" disabled
                                                        class="multiselect-custom custom-title-multiselect"
                                                        :canClear="false" :closeOnSelect="true" :object="true"
                                                        noOptionsText="No Result" :canDeselect="false" />
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-12">
                                                    <input type="text" v-model="numbersettingset.mobileno"
                                                        class="form-control" placeholder="Enter Mobile No."
                                                        maxlength="10" id="formMobileNoInput"
                                                        @keypress="onlyNumberMobile" autocomplete="off" />
                                                </div>
                                                <div class="custom-error" v-if="numbersettingset.mobileno_err">
                                                    {{ numbersettingset.mobileno_err }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-12">
                                        <button type="button" title="Remove" class="btn custom-outline-delete-btn"
                                            style="margin-top: 31px;" @click="remove(index)" v-show="index > 0">
                                            <i class="pi pi-trash delete-btn-color"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="mobilevalueset.length < 3">
                            <div class="col-lg-12 col-md-12 col-12 text-center">
                                <div class="custom-form-group">
                                    <button type="button" class="btn custom-view-detail-btn"
                                        @click="addMoreInputMobile()"><i class="pi pi-plus me-1"
                                            style="font-size: 12px;"></i>Add More Mobile No.</button>
                                </div>
                            </div>
                        </div>
                        <Divider class="mb-3" />
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12 text-center">
                                <div class="custom-form-group">
                                    <button type="button" class="header-btn-outer btn btn-primary"
                                        :style="{ width: '100px' }" @click="submitSmsMsgBtn()" :disabled="btnloader">
                                        <span v-if="!btnloader">Submit</span>
                                        <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="btnloader">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <div class="preview-card-template" v-if="this.smsmessage != ''">
                    <div class="preview-body-msg">
                        <p v-html="this.smsmessageinput"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
    data() {
        return {
            v$: useValidate(),
            smsmessage: '',
            // mobilenumberset: [],
            smsmessageinput: '',
            btnloader: false,
            smsconvertedvalue: '',
            countrycode: { value: 101, label: "+91" },
            countryCodeList: [],
            mobilevalueset: [
                {
                    mobileno: "",
                },
            ],
            error_status: false,
            mobileno_err: '',
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },
    validations() {
        return {
            smsmessage: {
                required: helpers.withMessage("Please enter message", required),
            },
            // mobilenumberset: {
            //     required: helpers.withMessage("Please enter mobile No.", required),
            // }
        };
    },
    mounted() {
    },

    methods: {
        addMoreInputMobile() {
            this.checkValidation();
            this.v$.$validate();
        },
        remove(index) {
            this.mobilevalueset.splice(index, 1);
        },
        checkValidation() {
            for (let index = 0; index < this.mobilevalueset.length; index++) {
                let element = this.mobilevalueset[index];
                let org_state = [...this.mobilevalueset];
                let org_element = { ...org_state[index] };
                if (!element.mobileno || element.mobileno.length != 10) {
                    org_element.mobileno_err = "Please enter vaild mobile No.";
                } else {
                    org_element.mobileno_err = "";
                    this.mobilevalueset.push({
                        mobileno: '',
                    });
                    
                }
                org_state[index] = org_element;
                this.mobilevalueset = org_state;
                if (org_state[0] && org_state[0].mobileno_err != "") {
                    this.error_status = true;
                    console.log("test1");
                } else {
                    this.error_status = false;
                    console.log("test2");
                }
            }
        },
        addInputValue() {
            if (
                this.mobilevalueset.length > 0
            ) {
                this.mobilevalueset.push({
                    mobileno: '',
                });
            } else if (this.mobilevalueset.length == 0) {
                this.mobilevalueset.push({
                    mobileno: '',
                });
            }
        },
        submitSmsMsgBtn() {
            this.checkValidation();
            this.v$.$validate();
            let fields = {};
            this.smsconvertedvalue = this.smsmessage.replace(/<[^>]+>/g, '');
            fields["message"] = this.smsconvertedvalue;
            fields["receiverNumber"] = this.mobilenumberset;
            if (!this.v$.$error) {
                this.btnloader = true;
                console.log(fields);
                // this.ApiService.sendSms(fields).then((data) => {
                //     if (data.status == 200) {
                //         this.btnloader = false;
                //         var successMsg = data.message;
                //         this.$toast.open({
                //             message: successMsg,
                //             type: "success",
                //             duration: 3000,
                //             position: "top-right",
                //         });
                //         setTimeout(() => {
                //             this.v$.$reset();
                //         }, 0);
                //         this.smsmessage = '';
                //         this.mobilenumberset = [];
                //         this.smsmessageinput = '';
                //     } else {
                //         this.btnloader = false;
                //         var errorMsg = data.message;
                //         this.$toast.open({
                //             message: errorMsg,
                //             type: "error",
                //             duration: 3000,
                //             position: "top-right",
                //         });
                //     }
                // });
            } else {
                this.$error;
            }
        },
        htmlconvertmsg() {
            var messagevalue = this.smsmessage;
            this.smsmessageinput = messagevalue.replace(/\n/g, '<br/>');
        },
        onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("formMobileNoInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        CommaSeparatedMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode != 44) {
                $event.preventDefault();
            }
        },
    },
};
</script>
<style scoped>
.preview-card-template {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 14px 15px;
    height: auto;
    min-height: 197px;
}

.preview-body-msg {
    font-family: "AcuminPro-Regular";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 17px;
    margin-top: 15px;
}

.intelligent-campagin-modal-header {
    font-family: 'AcuminPro-SemiBold';
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0B294B;
    line-height: 18px;
    margin-top: 10px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 512px; */
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}
</style>